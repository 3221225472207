import React from "react";

const Dialog = ({ onConfirm, onCancel, name, product }) => {
  return (
    <div className="overlay">
      <div className="delete-confirmation-dialog">
        {product ? (
          <p>Are you sure you want to export products?</p>
        ) : (
          <p>Are you sure you want to delete this {name}?</p>
        )}
        <button className="confirm" onClick={onConfirm}>
          Yes
        </button>
        <button className="cancel" onClick={onCancel}>
          No
        </button>
      </div>
    </div>
  );
};

export default Dialog;
