export const handleError = (
  error,
  setSnackbarMessage,
  setSnackbarColor,
  setShowSnackbar,
  setLoading = undefined,
  setLoadingCreate = undefined
) => {
  let errorMessage = "Error fetching the request";

  if (error.response) {
    errorMessage = error.response.data.message || "Server error";
  } else if (error.request) {
    errorMessage = "No response received from the server";
  }

  setSnackbarMessage(errorMessage);
  setSnackbarColor({ backgroundColor: "#FF0000" });
  setShowSnackbar(true);
  if (typeof setLoadingCreate === "function") {
    setLoadingCreate(false);
  }
  if (typeof setLoadingCreate === "function") {
    setLoading(false);
  }

  setTimeout(() => {
    setShowSnackbar(false);
  }, 2000);
};
export const handleErrorUpload = (
  error,
  setUploadProgressMessage,
  // setSnackbarColor,
  setShowError,
  // setLoading = undefined,
  // setLoadingCreate = undefined
) => {
  let errorMessage = "Error fetching the request";

  if (error.response) {
    errorMessage = error.response.data.message || "Server error";
  } else if (error.request) {
    errorMessage = "No response received from the server";
  }

  setUploadProgressMessage(errorMessage);
  // setSnackbarColor({ backgroundColor: "#FF0000" });
  setShowError(true);
  // if (typeof setLoadingCreate === "function") {
  //   setLoadingCreate(false);
  // }
  // if (typeof setLoadingCreate === "function") {
  //   setLoading(false);
  // }

  // setTimeout(() => {
  //   setShowSnackbar(false);
  // }, 2000);
};
export const handleFilterChange = (
  queryParams,
  location,
  history,
  fieldName,
  value,
  filter
) => {
  if (value) {
    queryParams.delete("page");
    queryParams.set(
      filter === true ? `${fieldName}` : `filter_${fieldName}`,
      value
    );
    queryParams.set("page", 1);
  } else {
    queryParams.delete(
      filter === true ? `${fieldName}` : `filter_${fieldName}`
    );
  }
  const newUrl = `${location.pathname}?${queryParams.toString()}`;
  history.replace(decodeURIComponent(newUrl));
};
