import Cookies from "js-cookie";
export var path = "";

var host = "https://tripolifreshmarket-devapi-650ee9764376.herokuapp.com/api/";

export var pixelID = "";
export const source = {
  source: "2",
};
let token = Cookies.get("token");

// while (typeof token === "undefined") {
//   token = Cookies.get("token");
// }
let auth = "";

if (typeof token === "undefined") {
  // console.log("WHAT TJE FUCL");
  // Keep checking for token until it's not undefined
  // while (typeof token === "undefined") {
  token = Cookies.get("token");
  // }
}

// After token is obtained and not undefined, construct the Authorization header
if (typeof token !== "undefined") {
  auth = "Bearer " + Cookies.get("token");
}
export let headers = {
  source: "2",
  Authorization: auth,
};

var path1 = "";
var urls = {
  getCategoryDropdown: "category/getCategoryDropdown",
  category: "category",
  categories: "categories",
  products: "products",
  product: "product",
  units: "units",
  unit: "unit",
  order_statuses: "order_statuses",
  order_status: "order_status",
  admin_login: "admin_login",
  orders: "orders",
  order: "order",
  adminOrder: "admin_order",
  users: "users",
  user: "user",
  permissions: "permissions",
  settings: "settings",
  customers: "customers",
  customer: "customer",
  banners: "banners",
  banner: "banner", 
  detailedProducts:"products/detailed"
};

function buildLink(link) {
  // https://tripolifreshmarket-devapi-650ee9764376.herokuapp.com/api/

  host = "https://tripolifreshmarket-devapi-650ee9764376.herokuapp.com/api/";

  return host + path1 + urls[link];
}
export default buildLink;
