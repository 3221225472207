import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import buildLink from "../../urls";
import Cookies from "js-cookie";
import OrderLog from "./OrderLog";
import Loading from "../LoadingError/Loading";
import Snackbar from "../LoadingError/snackbar";
import ButtonLoader from "../LoadingError/buttonLoader";
import Dialog from "../dialogue";
import { handleError } from "../../services/functions";
import Invoice from "./invoice";
import Modal from "./modal";

const OrderDetailmain = () => {
  const [orderDeatils, setOrderDetail] = useState();
  const [deliveryFees, setDeliveryFees] = useState();
  const [rate, setRate] = useState(orderDeatils?.rate || "");
  const [address, setAddress] = useState();
  const [comment, setComment] = useState("");
  const [status, setStatus] = useState({ id: 1, name: "", description: "" });
  const [statuses, setStatuses] = useState();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarColor, setSnackbarColor] = useState({});
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [orderProduct, setOrderProducts] = useState();
  const [editingAddress, setEditingAddress] = useState(false);
  const [editingComment, setEditingComment] = useState(false);
  const [isInvoiceOpen, setIsInvoiceOpen] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);
  const [updated, setUpdated] = useState(false);
  const addressRef = useRef(null);
  const commentRef = useRef(null);

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [orderToDelete, setOrderToDelete] = useState(null);
  const [tempStatus, setTempStatus] = useState({
    id: status.id,
    name: status.name,
    description: status.description,
  });
  const history = useHistory();
  const [loading, setLoading] = useState();

  const [updatedProducts, setUpdatedProducts] = useState();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const orderId = queryParams?.get("order_id");

  useEffect(() => {
    if (orderDeatils) {
      setAddress(orderDeatils.address);
      setComment(orderDeatils.comment);
      setStatus({
        id: orderDeatils.order_status_id,
        name: orderDeatils.order_status,
        description: orderDeatils.description,
      });
      setDeliveryFees(orderDeatils?.net_delivery_fees || "");
      setRate(orderDeatils.rate || "");
      setOrderProducts(orderDeatils.products);
      setUpdatedProducts(
        orderDeatils?.products?.map((item) => ({
          ...item,
          new_price:
            item.new_price !== null
              ? item?.new_price?.substring(1)
              : item.net_price || "",
          new_quantity:
            item.new_quantity !== null
              ? item.new_quantity
              : item.quantity || "",
        }))
      );
      // console.log(statuses);
      // setUpdatedProducts(
      //   orderDeatils?.products?.map((item) => ({
      //     order_product_id: item.order_product_id,
      //     new_price:
      //       item.new_price != null
      //         ? item.new_price.substring(1)
      //         : item.new_price || "",
      //     new_quantity: item.new_quantity || "",
      //   }))
      // );
      // console.log("test", orderProduct);
      if (updatedProducts) {
        // console.log("updatedproducts", updatedProducts);
      }
    }
  }, [orderDeatils]);

  const toggleInvoiceModal = () => {
    setIsInvoiceOpen(!isInvoiceOpen);
  };
  const saveOrder = async () => {
    if (orderDeatils) {
      const updatedOrderProducts = updatedProducts.map((product) => {
        if (product.new_quantity === null) {
          product.new_quantity = product.quantity;
        }

        // if (product.new_price.includes('$')) {
        //   console.log("wtf");
        //   product.new_price = product.new_price.substring(1);
        // }

        if (product.new_price === null || product.new_price === "") {
          // console.log("wtf");
          product.new_price = product.net_price;
        }

        return product;
      });

      if (updatedOrderProducts) {
        // console.log("updatedOrderProducts", updatedOrderProducts);
      }
      const requestData = {
        address: address,
        rate: rate,
        order_log_row_count: `${orderDeatils?.order_log.length}`,
        comment: comment,
        delivery_fees: deliveryFees,
        status_id: tempStatus.id ? tempStatus.id : orderDeatils.order_status_id,
        products: updatedOrderProducts, // Use the updated orderProduct array
      };

      console.log("requestData", requestData.products);
      const hasNaN = requestData.products?.some(
        (price) => isNaN(price.new_price) || isNaN(price.new_quantity)
      );
      const zeroValue = (value) => value == 0;

      const hasZeroQuantityOrPrice = (product) => {
        return zeroValue(product.new_quantity) || zeroValue(product.new_price);
      };

      const hasZero = requestData.products?.some(hasZeroQuantityOrPrice);

      // console.log(tempStatus);
      // console.log("requested data", requestData);
      if (address === "" || deliveryFees === "" || rate === "") {
        let errorMessage = "";
        if (address === "") errorMessage += "Address, ";
        if (deliveryFees === "") errorMessage += "Delivery Fees, ";
        if (rate === "") errorMessage += "Rate, ";

        errorMessage = errorMessage.slice(0, -2);
        setSnackbarMessage(`${errorMessage} should not be empty.`);
        setSnackbarColor({ backgroundColor: "#FF0000" });
        setShowSnackbar(true);
        setLoadingCreate(false);
        setTimeout(() => {
          setShowSnackbar(false);
        }, 1200);
      } else if (hasNaN || hasZero) {
        setSnackbarMessage(
          "Product new price or new quantity cannot be NaN or Zero."
        );
        setSnackbarColor({ backgroundColor: "#FF0000" });
        setShowSnackbar(true);
        setTimeout(() => {
          setShowSnackbar(false);
        }, 3000);
        return;
      } else {
        if (!hasNaN && !hasZero) {
          setLoadingCreate(true);
          try {
            const response = await axios.put(
              buildLink("adminOrder") + `?order_id=${orderId}`,
              requestData,
              {
                headers: {
                  source: "2",
                  Authorization: "Bearer " + Cookies.get("token"),
                },
              }
            );
            if (response.status === 200 || response.status === 201) {
              setUpdated(true);
              setSnackbarMessage("Order updated successfully!");
              setSnackbarColor({ backgroundColor: "#4CAF50" });
              setShowSnackbar(true);
              setLoadingCreate(false);
              setTimeout(() => {
                setShowSnackbar(false);
                // history.push("/orders");
              }, 1200);
            } else {
              console.error("Error:", response);
              handleError(
                response,
                setSnackbarMessage,
                setSnackbarColor,
                setShowSnackbar,
                setLoading,
                setLoadingCreate
              );
            }
          } catch (error) {
            handleError(
              error,
              setSnackbarMessage,
              setSnackbarColor,
              setShowSnackbar,
              setLoading,
              setLoadingCreate
            );
          } finally {
            setLoading(false);
          }
        }
      }
    }
  };
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        buildLink("order") + `?order_id=${orderId}`,
        {
          headers: {
            source: "2",
            Authorization: "Bearer " + Cookies.get("token"),
          },
        }
      );
      if (response.status === 200) {
        setUpdated(false);
        setOrderDetail(response.data.data);
      } else {
        console.error("Error:", response);
        handleError(
          response,
          setSnackbarMessage,
          setSnackbarColor,
          setShowSnackbar,
          setLoading
        );
      }
    } catch (error) {
      handleError(
        error,
        setSnackbarMessage,
        setSnackbarColor,
        setShowSnackbar,
        setLoading
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (orderId && updated !== true) {
      console.log("order");
      fetchData();
    }
  }, [orderId]);

  useEffect(() => {
    if (updated === true) {
      console.log("updated");
      fetchData();
    }
  }, [updated]);

  const fetchStatuses = async () => {
    setStatusLoading(true);
    try {
      const response = await axios.get(buildLink("order_statuses"), {
        headers: {
          source: "2",
          Authorization: "Bearer " + Cookies.get("token"),
        },
      });
      if (response.status === 200) {
        setStatuses(response.data.data);
        setStatusLoading(false);
      } else {
        console.error("Error:", response);
        handleError(
          response,
          setSnackbarMessage,
          setSnackbarColor,
          setShowSnackbar
        );
        setStatusLoading(false);
      }
    } catch (error) {
      setStatusLoading(false);
      handleError(error, setSnackbarMessage, setSnackbarColor, setShowSnackbar);
    }
  };
  useEffect(() => {
    fetchStatuses();
  }, []);

  const handlePriceChange = (index, value) => {
    const updated = [...updatedProducts];

    // console.log("updated product", updated);
    updated[index].new_price = value;
    setOrderProducts(
      updated.map((item) => ({
        order_product_id: item.order_product_id,
        new_price: item.new_price || "",
        new_quantity: item.new_quantity || "",
      }))
    );
    setUpdatedProducts(updated);
    // console.log(updatedProducts, "here testing");
  };

  const handleQuantityChange = (index, value) => {
    // console.log("value", value);
    const updated = [...updatedProducts];
    // console.log("updated before", updated);
    updated[index].new_quantity = value;
    // console.log("updated", updated);
    setOrderProducts(
      updated.map((item) => ({
        order_product_id: item.order_product_id,
        new_price: item.new_price || "",
        new_quantity: item.new_quantity || "",
      }))
    );
    setUpdatedProducts(updated);
    // console.log(updatedProducts, "here testing");
  };

  // const handlePrint = () => {
  //   // window.print();
  //   setPrint(true);
  // };

  const handleDeliveryFeesChange = (e) => {
    setDeliveryFees(e.target.value);
  };

  const handleRateChange = (e) => {
    setRate(e.target.value);
  };

  useEffect(() => {
    setTempStatus({
      id: status.id,
      name: status.name,
      description: status.description,
    });
  }, [status]);

  const handleTempStatusChange = (event) => {
    setTempStatus({
      id: parseInt(event.target.value),
      name: event.target.value,
      description: event.target.value,
    });
    // console.log(event.target.value);
  };

  const deleteOrder = async (order) => {
    try {
      setLoading(true);
      const response = await axios.delete(
        buildLink("order") + `?order_id=${order.order_id}`,
        {
          headers: {
            source: "2",
            Authorization: "Bearer " + Cookies.get("token"),
          },
        }
      );
      // console.log(response.data.success, "dsads");
      if (response.status === 200) {
        setLoading(false);
        setSnackbarMessage(response.data.data.message);
        setSnackbarColor({ backgroundColor: "#4CAF50" });
        setShowSnackbar(true);
        setTimeout(() => {
          setShowSnackbar(false);
          history.push("/orders");
        }, 1200);
      } else {
        console.error("Error:", response);
        handleError(
          response,
          setSnackbarMessage,
          setSnackbarColor,
          setShowSnackbar,
          setLoading
        );
      }
    } catch (error) {
      handleError(
        error,
        setSnackbarMessage,
        setSnackbarColor,
        setShowSnackbar,
        setLoading
      );
    } finally {
      setLoading(false);
    }
  };
  const handleDeleteClick = (order) => {
    setOrderToDelete(order);
    setShowDeleteConfirmation(true);
  };

  const handleConfirmDelete = async () => {
    if (orderToDelete) {
      await deleteOrder(orderToDelete);
    }

    setShowDeleteConfirmation(false);
    setOrderToDelete(null);
  };

  const handleCancelDelete = () => {
    setShowDeleteConfirmation(false);
    setOrderToDelete(null);
  };

  const handleAddressClick = () => {
    setEditingAddress(true);
  };

  const handleCommentClick = () => {
    setEditingComment(true);
  };

  const handleAddressChange = (e) => {
    setAddress(e.target.value);
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (addressRef.current && !addressRef.current.contains(event.target)) {
        setEditingAddress(false);
      }
      if (commentRef.current && !commentRef.current.contains(event.target)) {
        setEditingComment(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const generateWhatsAppLink = () => {
    const phoneNumber = orderDeatils?.telephone;
    return `https://wa.me/${phoneNumber}`;
  };

  return (
    <section className="content-main">
      {loading && orderId ? (
        <Loading />
      ) : (
        <>
          <div className="content-header">
            <div
              onClick={() => history.goBack()}
              className="btn bgcolor text-white btnhover"
            >
              Back
            </div>
            <div className="current-status">
              <div className="">
                <b>
                  {/* <span className="text-color">Current Status: </span> */}
                  <span>{status?.description}</span>
                </b>
              </div>
              {/* <div className="statusname">{status.name}</div> */}
            </div>
            {/* <div className="d-grid mt-3"> */}
            <button
              className="text-white delete-order-button"
              // to="/editorder"\
              disabled={showDeleteConfirmation}
              onClick={() => handleDeleteClick(orderDeatils)}
            >
              {showDeleteConfirmation ? (
                <div style={{ width: "75px" }}>
                  <ButtonLoader />
                </div>
              ) : (
                <>Delete</>
              )}
            </button>

            <div className="current-status-mobile">
              <div className="">
                <b>
                  {/* <span className="text-color">Current Status: </span> */}
                  {status?.description}
                </b>
              </div>
              {/* <div className="statusname">{status.name}</div> */}
            </div>
            {/* </div> */}
          </div>

          <div className="card">
            <header className="card-header-order p-3">
              <div className="d-flex justify-content-between align-items-start">
                <div className="d-flex flex-column  align-items-start">
                  <div>
                    <p className="mb-1">
                      <b>
                        Order ID:{" "}
                        {orderDeatils?.order_id.toString().padStart(4, "0")}
                      </b>
                    </p>
                  </div>
                  <div className="d-flex align-items-center">
                    <div>
                      <p className="mb-0">
                        {" "}
                        <b>Date: {orderDeatils?.date_added}</b>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="orderheader align-items-start">
                  {/* <div className="current-status">
                    <div className="text-color">Current Status</div>
                    <div className="statusname">{status.name}</div>
                  </div> */}
                  {status.id !== 8 && (
                    <select
                      id="parentstatus"
                      className="form-select d-inline-block mb-1 me-2 custom-select-order"
                      // style={{ maxWidth: "17rem", width: "17rem" }}
                      onChange={handleTempStatusChange}
                      value={tempStatus.id}
                      disabled={status.id === 8 || statusLoading}
                      placeholder="Select Status"
                    >
                      <option key={"0"} value={status.id} disabled>
                        Select Status
                      </option>

                      {status.id === 1 && (
                        <>
                          {statuses?.map((status) => {
                            if (
                              status.order_status_id === 3 ||
                              status.order_status_id === 4
                            ) {
                              return (
                                <option
                                  key={status.order_status_id}
                                  value={status.order_status_id}
                                >
                                  {status.name}
                                  {/* ({status.description}) */}
                                </option>
                              );
                            }
                            return null;
                          })}
                        </>
                      )}
                      {status.id === 3 && (
                        <>
                          {statuses?.map((status) => {
                            if (
                              status.order_status_id === 1 ||
                              status.order_status_id === 2
                            ) {
                              return (
                                <option
                                  key={status.order_status_id}
                                  value={status.order_status_id}
                                >
                                  {status.name}
                                  {/* ({status.description}) */}
                                </option>
                              );
                            }
                            return null;
                          })}
                        </>
                      )}
                      {status.id === 6 && (
                        <>
                          {statuses?.map((status) => {
                            if (status.order_status_id === 7) {
                              return (
                                <option
                                  key={status.order_status_id}
                                  value={status.order_status_id}
                                >
                                  {status.name}
                                  {/* ({status.description}) */}
                                </option>
                              );
                            }
                            return null;
                          })}
                        </>
                      )}
                      {status.id === 7 && (
                        <>
                          {statuses?.map((status) => {
                            if (status.order_status_id === 8) {
                              return (
                                <option
                                  key={status.order_status_id}
                                  value={status.order_status_id}
                                >
                                  {status.name}
                                  {/* ({status.description}) */}
                                </option>
                              );
                            }
                            return null;
                          })}
                        </>
                      )}
                    </select>
                  )}
                  <a
                    href={generateWhatsAppLink()}
                    className="btn btn-primary mb-2 me-2 btnhover"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-whatsapp"></i>
                  </a>
                  <button
                    // style={{ width: "3rem", height: "40px" }}
                    className={`btn btn-success mb-2 me-2`}
                    onClick={toggleInvoiceModal}
                  >
                    <i className="fas fa-print"></i>
                  </button>
                  <button
                    className="text-white edit-order-button mb-2 me-2"
                    disabled={loadingCreate}
                    onClick={saveOrder}
                    // style={{ width: "80px", height: "40px" }}
                  >
                    {loadingCreate ? <ButtonLoader /> : <>Save</>}
                  </button>
                  {/* <button
                    className={`btn btn-success ${
                      !loadingCreate ? "mb-2" : "mb-1"
                    }`}
                    onClick={toggleInvoiceModal}
                  >
                    <i className="fas fa-print"></i>
                  </button> */}
                </div>
              </div>
            </header>

            <div className="card-body card receipt">
              <div className="p d-flex flex-wrap">
                <div className="flex-grow-1 mb-3 me-3">
                  <article className="icontext align-items-start">
                    <span className="icon icon-sm rounded-circle alert-success">
                      <i className="textcolor fas fa-user"></i>
                    </span>
                    <div className="text" style={{ width: "200px" }}>
                      {/* <h6 className="mb-0">Customer</h6> */}
                      <p
                        className="mb-0"
                        style={{
                          overflowY: "auto",
                          // textOverflow: "ellipsis",
                          width: "200px",
                          // paddingTop: "0.6rem",
                          height: "5.5rem",
                          display: "-webkit-box",
                          WebkitLineClamp: 3,
                          WebkitBoxOrient: "vertical",
                        }}
                      >
                        {orderDeatils?.fullname}
                        <br />
                        {orderDeatils?.telephone}
                      </p>
                    </div>
                  </article>
                </div>
                <div className="flex-grow-1 mb-3 me-3">
                  <article className="icontext align-items-start">
                    <span className="icon icon-sm rounded-circle alert-success">
                      <i className="textcolor fas fa-map-marker-alt"></i>
                    </span>
                    <div className="text">
                      {/* <h6 className="mb-0">Address</h6> */}
                      {editingAddress ? (
                        <textarea
                          ref={addressRef}
                          type="text"
                          placeholder="Click here to edit"
                          className="form-control"
                          style={{
                            width: "250px",
                            height: "5.5rem",
                            maxHeight: "6.5rem",
                          }}
                          id="address"
                          maxLength={40}
                          value={address}
                          onChange={handleAddressChange}
                          disabled={orderDeatils?.order_status_id !== 1}
                          // onBlur={handleAddressBlur}
                        />
                      ) : (
                        <p
                          className="mb-0"
                          style={{
                            width: "250px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            cursor: "pointer",
                            paddingTop: "0.6rem",
                            // width: "200px",
                            height: "5.5rem",
                            display: "-webkit-box",
                            WebkitLineClamp: 3,
                            WebkitBoxOrient: "vertical",
                          }}
                          onClick={handleAddressClick}
                        >
                          {address || "Click here to edit"}
                        </p>
                      )}
                    </div>
                  </article>
                </div>
                <div className="flex-grow-1 mb-3 me-3">
                  <article className="icontext align-items-start">
                    <span className="icon icon-sm rounded-circle alert-success">
                      <i className="textcolor fas fa-truck-moving"></i>
                    </span>
                    <div className="text">
                      {/* <h6 className="mb-0 text-white">.</h6> */}
                      {editingComment ? (
                        <textarea
                          type="text"
                          ref={commentRef}
                          placeholder="Add a comment"
                          className="form-control bg-grey"
                          style={{
                            width: "250px",
                            height: "5.5rem",
                            maxHeight: "6.5rem",
                            // marginTop: "0.4rem",
                          }}
                          value={comment}
                          onChange={handleCommentChange}
                          disabled={orderDeatils?.order_status_id !== 1}
                          // onBlur={handleCommentBlur}
                        />
                      ) : (
                        <div
                          className="mb-0"
                          style={{
                            width: "250px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            cursor: "pointer",
                            paddingTop: "0.6rem",
                            // width: "200px",
                            height: "5.5rem",
                            display: "-webkit-box",
                            WebkitLineClamp: 3,
                            WebkitBoxOrient: "vertical",
                          }}
                          onClick={handleCommentClick}
                        >
                          {comment || "Add a comment "}
                        </div>
                      )}
                    </div>
                  </article>
                </div>
              </div>

              <div className="flexin-table">
                <div className="col-lg-9">
                  <div className="table-responsive receipt-body">
                    <table className="table border table-lg tabletd">
                      <thead>
                        <tr>
                          <th>Product</th>
                          <th>Old Price</th>
                          <th>New Price</th>
                          <th>Old Quantity</th>
                          <th>New Quantity</th>
                          <th>Unit</th>
                          <th>HQ</th>
                        </tr>
                      </thead>
                      <tbody>
                        {updatedProducts?.map((item, index) => (
                          <tr key={index}>
                            <td className="text-center">
                              <div className="flex ">
                                <div
                                  style={{ width: "9rem", marginTop: "0.5rem" }}
                                >
                                  {item.name || "-"}
                                </div>
                              </div>
                            </td>
                            <td className="text-center">
                              <div className="flex ">
                                <div
                                  className="mt-2"
                                  style={{ fontSize: "1rem", width: "6rem" }}
                                >
                                  {item.net_price || "-"} $
                                </div>
                              </div>
                            </td>
                            <td className="text-center">
                              <div className="flex ">
                                <div style={{ width: "6rem" }}>
                                  <input
                                    type="number"
                                    value={item.new_price}
                                    className={
                                      orderDeatils?.order_status_id !== 1
                                        ? "cursordefault form-control"
                                        : " form-control"
                                    }
                                    disabled={
                                      orderDeatils?.order_status_id !== 1
                                    }
                                    style={{
                                      fontSize: "1rem",
                                      height: "2.4rem",
                                      marginTop: "0.5rem",
                                    }}
                                    step={0.01}
                                    min={0.01}
                                    onChange={(e) =>
                                      handlePriceChange(index, e.target.value)
                                    }
                                    onWheel={(event) =>
                                      event.currentTarget.blur()
                                    }
                                  />
                                </div>
                              </div>
                            </td>
                            <td className="text-center">
                              <div className="flex ">
                                <div
                                  className="mt-2"
                                  style={{ fontSize: "1rem", width: "6rem" }}
                                >
                                  {item.quantity || "-"}
                                </div>
                              </div>
                            </td>
                            <td className="text-center">
                              <div className="flex ">
                                <div style={{ width: "6rem" }}>
                                  <input
                                    type="number"
                                    value={item.new_quantity}
                                    style={{
                                      fontSize: "1rem",
                                      height: "2.4rem",
                                      marginTop: "0.5rem",
                                    }}
                                    className={
                                      orderDeatils?.order_status_id !== 1
                                        ? "cursordefault form-control"
                                        : " form-control"
                                    }
                                    step={0.01}
                                    min={0}
                                    disabled={
                                      orderDeatils?.order_status_id !== 1
                                    }
                                    onChange={(e) =>
                                      handleQuantityChange(
                                        index,
                                        e.target.value
                                      )
                                    }
                                    onWheel={(event) =>
                                      event.currentTarget.blur()
                                    }
                                  />
                                </div>
                              </div>
                            </td>
                            <td
                              className="text-center"
                              // style={{ width: "6rem" }}
                            >
                              <div className="flex ">
                                <div className="mt-1" style={{ width: "4rem" }}>
                                  {item.unit || "-"}
                                </div>
                              </div>
                            </td>
                            <td
                              className="text-center"
                              // style={{ width: "6rem" }}
                            >
                              <div className="flex" style={{ width: "4rem" }}>
                                <div className="mt-1">
                                  {item?.show_price == 0 ? "+" : "-"}
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="side-margin" style={{ width: "18.3rem" }}>
                  <div className="box shadow-sm bg-light mt-2">
                    <div className="mb-2">
                      <b>Total $:</b> &nbsp;
                      {orderDeatils?.net_total_USD || "-"}
                    </div>
                    <div className="mb-2">
                      <b>Total LBP:</b> &nbsp;
                      {new Intl.NumberFormat("en-US").format(
                        orderDeatils?.net_total_LBP
                      ) || "-"}
                    </div>
                  </div>
                  <div className="box shadow-sm bg-light mt-2">
                    <div className="mb-2 row">
                      <div
                        className="col-auto"
                        style={{ paddingTop: "0.24rem" }}
                      >
                        <b>Delivery fees $:</b>
                      </div>
                      {/* <div className="col" > */}
                      <input
                        type="number"
                        className={
                          orderDeatils?.order_status_id !== 1
                            ? "cursordefault form-control"
                            : " form-control"
                        }
                        style={{ width: "7rem", height: "2rem" }}
                        value={deliveryFees}
                        step={1}
                        min={0}
                        max={999}
                        maxLength={3}
                        // onChange={handleDeliveryFeesChange}
                        pattern="[0-9]{3}"
                        // value={deliveryFees}
                        onChange={(e) => {
                          const input = e.target.value;
                          if (/^\d{0,3}$/.test(input)) {
                            setDeliveryFees(input);
                          }
                        }}
                        onKeyDown={(e) => {
                          if (
                            e.key === "." ||
                            e.key === "," ||
                            e.key === "-" ||
                            e.key === "e" ||
                            e.key === "+"
                          ) {
                            e.preventDefault();
                          }
                        }}
                        onWheel={(event) => event.currentTarget.blur()}
                        required
                        disabled={orderDeatils?.order_status_id !== 1}
                      />
                      {/* </div> */}
                    </div>
                    <div className="mb- row">
                      <div
                        className="col-auto"
                        style={{ paddingTop: "0.25rem" }}
                      >
                        <b>Rate: </b>
                      </div>
                      {/* <div className="col"> */}
                      <input
                        type="number"
                        // className='form-control '
                        className={
                          orderDeatils?.order_status_id !== 1
                            ? "cursordefault form-control"
                            : " form-control"
                        }
                        style={{ width: "7rem", height: "2rem" }}
                        value={rate}
                        step={0.01}
                        min={0.01}
                        onWheel={(event) => event.currentTarget.blur()}
                        onChange={handleRateChange}
                        disabled={orderDeatils?.order_status_id !== 1}
                      />
                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </div>
              {orderDeatils?.order_log.length > 0 && (
                <>
                  <h4 className="mb-4 mt-3">
                    <u>Order Log:</u>
                  </h4>
                  <div className="table-responsive receipt-body">
                    <OrderLog order={orderDeatils} />
                  </div>
                </>
              )}
            </div>
          </div>
          {showSnackbar && (
            <Snackbar message={snackbarMessage} style={snackbarColor} />
          )}
          {showDeleteConfirmation && (
            <Dialog
              onConfirm={handleConfirmDelete}
              onCancel={handleCancelDelete}
              name={"order"}
            />
          )}
          <Modal isOpen={isInvoiceOpen} onClose={toggleInvoiceModal}>
            <Invoice order={orderDeatils} />
          </Modal>
        </>
      )}
    </section>
  );
};

export default OrderDetailmain;
