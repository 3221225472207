import React, { useState } from "react";
import { Link } from "react-router-dom";
import Loading from "../LoadingError/Loading";
import Snackbar from "../LoadingError/snackbar";
import "../banners/banner.css";
import Dialog from "../dialogue";
import axios from "axios";
import buildLink from "../../urls";
import { handleError, handleFilterChange } from "../../services/functions";
import { useEffect } from "react";
import Cookies from "js-cookie";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import ReactPaginate from "react-paginate";

const MainBanners = () => {
  const [loading, setLoading] = useState(false);
  const [mssg, setMssg] = useState(false);
  const [banners, setBanners] = useState([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarColor, setSnackbarColor] = useState({});
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [bannerToDelete, setBannerToDelete] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [selectedPage, setSelectedPage] = useState(0);
  const [bannerId, setBannerId] = useState();
  const [title, setTitle] = useState();
  const [width, setWidth] = useState();
  const [height, setHeight] = useState();

  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [editableHeader, setEditableHeader] = useState(null);

  const initialPageNumber = queryParams?.get("page")
    ? Number(queryParams?.get("page")) - 1
    : 0;

  useEffect(() => {
    // const queryParams = new URLSearchParams(location.search);
    // queryParams.set("page", 1);
    // const newUrl = `${location.pathname}?${queryParams.toString()}`;
    // history.replace(decodeURIComponent(newUrl));
  }, []);

  const fetchBanners = async () => {
    try {
      setLoading(true);
      const bannerIdQueryParam = queryParams.get("filter_banner_id");
      const titleQueryParam = queryParams.get("filter_title");
      const pageQueryParam = queryParams.get("page");
      const widthQueryParam = queryParams.get("filter_width");
      const heightQueryParam = queryParams.get("filter_height");

      let apiUrl = buildLink("banners");

      const queryParamsArray = [];

      if (bannerIdQueryParam)
        queryParamsArray.push(`filter_banner_id=${bannerIdQueryParam}`);
      if (titleQueryParam)
        queryParamsArray.push(`filter_title=${titleQueryParam}`);
      if (pageQueryParam) queryParamsArray.push(`page=${pageQueryParam}`);
      if (widthQueryParam)
        queryParamsArray.push(`filter_width=${widthQueryParam}`);
      if (heightQueryParam)
        queryParamsArray.push(`filter_height=${heightQueryParam}`);

      // apiUrl += `?limit=10&${queryParamsArray.join("&")}`;
      apiUrl += `?${
        pageQueryParam ? "limit=10&" :  "limit=10" 
      }${queryParamsArray.join("&")}`;

      // if (pageQueryParam) {
      //   apiUrl += `?${
      //     !limitQueryParam ? "limit=10" : null
      //   }&${queryParamsArray.join("&")}`;

      const response = await axios.get(apiUrl, {
        headers: {
          source: "2",
          Authorization: "Bearer " + Cookies.get("token"),
        },
      });
      if (response.status === 200) {
        // console.log(response, "response");
        setBanners(response.data.data.banners);
        setPageCount(response.data.data.pages);
      } else {
        console.error("Error:", response);
        setMssg(true);
        handleError(
          response,
          setSnackbarMessage,
          setSnackbarColor,
          setShowSnackbar,
          setLoading
        );
        // }
      }
    } catch (error) {
      setMssg(true);
      handleError(
        error,
        setSnackbarMessage,
        setSnackbarColor,
        setShowSnackbar,
        setLoading
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBanners();
  }, [
    queryParams.get("page"),
    queryParams.get("filter_banner_id"),
    queryParams.get("filter_title"),
    queryParams.get("filter_width"),
    queryParams.get("filter_height"),
  ]);
  const deleteBanner = async (banner) => {
    try {
      setLoading(true);
      const response = await axios.delete(
        buildLink("banner") + `?banner_id=${banner.banner_id}`,
        {
          headers: {
            source: "2",
            Authorization: "Bearer " + Cookies.get("token"),
          },
        }
      );
      // console.log(response.data.success, "dsads");
      if (response.status === 200) {
        setLoading(false);
        setSnackbarMessage(response.data.data.message);
        setSnackbarColor({ backgroundColor: "#4CAF50" });
        setShowSnackbar(true);
        setTimeout(() => {
          setShowSnackbar(false);
        }, 1200);

        const filteredBanners = banners.filter(
          (ban) => ban.banner_id !== banner.banner_id
        );
        setBanners(filteredBanners);
        // console.log(response);
      } else {
        console.error("Error:", response);
        handleError(
          response,
          setSnackbarMessage,
          setSnackbarColor,
          setShowSnackbar,
          setLoading
        );
      }
    } catch (error) {
      handleError(
        error,
        setSnackbarMessage,
        setSnackbarColor,
        setShowSnackbar,
        setLoading
      );
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteClick = (e, banner) => {
    e.stopPropagation();
    setBannerToDelete(banner);
    setShowDeleteConfirmation(true);
  };

  const handleConfirmDelete = async () => {
    if (bannerToDelete) {
      await deleteBanner(bannerToDelete);
    }

    setShowDeleteConfirmation(false);
    setBannerToDelete(null);
  };

  const handleCancelDelete = () => {
    setShowDeleteConfirmation(false);
    setBannerToDelete(null);
  };

  const handleEdit = async (banner) => {
    history.push(`/addbanner?banner_id=${banner.banner_id}`);
  };

  const handlePageChange = (selected) => {
    setSelectedPage(selected.selected);
    const queryParams = new URLSearchParams(location.search);
    queryParams.set("page", selected.selected + 1);

    const newUrl = `${location.pathname}?${queryParams.toString()}`;
    history.replace(decodeURIComponent(newUrl));
  };

  const handleHeaderClick = (fieldName) => {
    setEditableHeader(fieldName);
  };
  const handleOutsideClick = (e) => {
    if (
      editableHeader &&
      !e.target.closest(".table th") &&
      e.target.tagName !== "INPUT"
    ) {
      setEditableHeader(null);
    }
  };

  const handleKeyPress = (e, fieldName) => {
    if (e.key === "Enter") {
      handleFilterChange(
        queryParams,
        location,
        history,
        fieldName,
        e.target.value
      );
    }
  };

  const handleBannerId = (e) => {
    handleKeyPress(e, "banner_id");
  };
  const handleTitle = (e) => {
    handleKeyPress(e, "title");
  };
  const handleWidth = (e) => {
    handleKeyPress(e, "width");
  };
  const handleHeight = (e) => {
    handleKeyPress(e, "height");
  };
  const clearFilters = () => {
    setBannerId("");
    setTitle("");
    setWidth("");
    setHeight("");

    queryParams.delete("filter_banner_id");
    queryParams.delete("filter_title");
    queryParams.delete("filter_width");
    queryParams.delete("filter_height");
    queryParams.set("page", 1);

    const newUrl = `${location.pathname}?${queryParams.toString()}`;
    history.replace(decodeURIComponent(newUrl));
  };

  useEffect(() => {
    document.body.addEventListener("click", handleOutsideClick);
    return () => {
      document.body.removeEventListener("click", handleOutsideClick);
    };
  }, [editableHeader]);

  return (
    <section className="content-main">
      <div className="content-header">
        <h2 className="content-title">Banners</h2>
        <div className="d-flex">
          <div className="clear-filter-btn me-2">
            <button onClick={clearFilters}>Clear Filters</button>
          </div>
          <div>
            <Link to="/addbanner" className="btn btn-primary">
              Create new
            </Link>
          </div>
        </div>
      </div>
      {loading ? (
        <Loading />
      ) : (
        <>
          {mssg ? (
            <div className="red-snackbar">{snackbarMessage}</div>
          ) : (
            <>
              <div className="table-responsive">
                <table className="table tabletd">
                  <thead>
                    <tr className="cursorpointer">
                      <th
                        scope="col"
                        onClick={() => handleHeaderClick("ID")}
                        style={{ width: "4rem" }}
                      >
                        {editableHeader === "ID" ? (
                          <input
                            type="text"
                            value={bannerId}
                            placeholder="ID"
                            onChange={(e) => setBannerId(e.target.value)}
                            onKeyPress={(e) => handleBannerId(e)}
                            style={{ width: "3rem" }}
                          />
                        ) : (
                          "ID"
                        )}
                      </th>
                      <th style={{ width: "17rem" }}>Image</th>
                      <th
                        scope="col"
                        onClick={() => handleHeaderClick("Title")}
                        style={{ width: "10rem" }}
                      >
                        {editableHeader === "Title" ? (
                          <input
                            type="text"
                            value={title}
                            placeholder="Title"
                            onChange={(e) => setTitle(e.target.value)}
                            onKeyPress={(e) => handleTitle(e)}
                            style={{ width: "8rem" }}
                          />
                        ) : (
                          "Title"
                        )}
                      </th>
                      <th
                        scope="col"
                        onClick={() => handleHeaderClick("Width")}
                        style={{ width: "9rem" }}
                      >
                        {editableHeader === "Width" ? (
                          <input
                            type="text"
                            value={width}
                            placeholder="Width"
                            onChange={(e) => setWidth(e.target.value)}
                            onKeyPress={(e) => handleWidth(e)}
                            style={{ width: "5rem" }}
                          />
                        ) : (
                          "Width"
                        )}
                      </th>
                      <th
                        scope="col"
                        onClick={() => handleHeaderClick("Height")}
                        style={{ width: "9rem" }}
                      >
                        {editableHeader === "Height" ? (
                          <input
                            type="text"
                            value={height}
                            placeholder="Height"
                            onChange={(e) => setHeight(e.target.value)}
                            onKeyPress={(e) => handleHeight(e)}
                            style={{ width: "5rem" }}
                          />
                        ) : (
                          "Height"
                        )}
                      </th>
                      {/* <th style={{ width: "6.8rem" }}>Date Added</th> */}
                      {/* <th style={{ width: "6.8rem" }}>Date Modified</th> */}
                      <th className="cursordefault" style={{ width: "9rem" }}>
                        Delete
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {banners?.map((banner) => (
                      <tr
                        className="cursorpointer"
                        key={`banner${banner.banner_id}`}
                        onClick={() => handleEdit(banner)}
                      >
                        <td className="text-center">
                          <div className="flex ">
                            <div style={{ width: "4rem" }}>
                              {banner.banner_id.toString().padStart(4, "0")}
                            </div>
                          </div>
                        </td>
                        <td className="text-center">
                          <div className="flex ">
                            <div style={{ width: "17rem" }}>
                              <img src={banner.image} />
                            </div>
                          </div>
                        </td>
                        <td className="text-center">
                          <div className="flex ">
                            <div
                              className="text_overflow"
                              style={{
                                width: "10rem",
                              }}
                            >
                              {banner.title}
                            </div>
                          </div>
                        </td>
                        <td className="text-center">
                          <div className="flex ">
                            <div style={{ width: "9rem" }}>{banner.width}</div>
                          </div>
                        </td>

                        <td className="text-center">
                          <div className="flex ">
                            <div style={{ width: "9rem" }}>
                              {banner.height || "-"}
                            </div>
                          </div>
                        </td>
                        {/* <td>
                          <div
                            style={{
                              display: "flex",
                            }}
                          >
                            <div style={{ width: "6.8rem" }}>
                              {banner?.date_added.split(" ")[0]}
                              <br />
                              {banner?.date_added.split(" ")[1]}
                            </div>
                          </div>
                        </td> */}
                        {/* <td className="text-center">
                          <div className="flex ">
                            <div style={{ width: "6.8rem" }}>
                              {banner?.date_modified.split(" ")[0]} <br />
                              {banner?.date_modified.split(" ")[1]}
                            </div>
                          </div>
                        </td> */}
                        <td
                          className="text-center cursorhand"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <div className="flex ">
                            {/* <button
                              className="action-btn edit-btn"
                              onClick={() => handleEdit(banner)}
                            >
                              <i className="far fa-pen"></i>
                            </button>
                            | */}
                            <button
                              className="action-btn btn btn-danger delete-btn"
                              onClick={(e) => handleDeleteClick(e, banner)}
                            >
                              <i className="far fa-trash-alt"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div style={{ marginTop: "0.5rem" }}>
                <ReactPaginate
                  pageCount={pageCount}
                  forcePage={initialPageNumber}
                  marginPagesDisplayed={2}
                  onPageChange={handlePageChange}
                  containerClassName="pagination"
                  breakClassName={`...`}
                  activeClassName="active-page"
                  previousLabel="< "
                  nextLabel=" >"
                  pageRangeDisplayed={3}
                  hrefAllControls={true}
                />
              </div>
              {showSnackbar && (
                <Snackbar message={snackbarMessage} style={snackbarColor} />
              )}
              {showDeleteConfirmation && (
                <Dialog
                  onConfirm={handleConfirmDelete}
                  onCancel={handleCancelDelete}
                  name={"banner"}
                />
              )}
            </>
          )}
        </>
      )}
    </section>
  );
};

export default MainBanners;
