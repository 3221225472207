import React, { useState } from "react";
import "./App.css";
import "./responsive.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import ProductScreen from "./screens/productScreen";
import CategoriesScreen from "./screens/CategoriesScreen";
import OrderScreen from "./screens/OrderScreen";
import Login from "./screens/LoginScreen";
import UsersScreen from "./screens/UsersScreen";
import NotFound from "./screens/NotFound";
import PrivateRouter from "./PrivateRouter";
import AddCategory from "./screens/AddCategory";
import UnitScreen from "./screens/UnitScreen";
import AddUnit from "./screens/AddUnit";
import OrderStatusScreen from "./screens/OrderStatusScreen";
import AddOrderStatus from "./screens/AddOrderStatus";
import AddUser from "./screens/AddUser";
import SettingsScreen from "./screens/SettingsScreen";
import AddSetting from "./screens/AddSetting";
import PriceListScreen from "./screens/PriceListScreen";
import BannersScreen from "./screens/BannersScreen";
import CustomersScreen from "./screens/CustomersScreen";
import AddCustomer from "./screens/AddCustomer";
import AddBanner from "./screens/AddBanner";
import OrderDetailScreen from "./screens/OrderDetailScreen";
import AddProduct from "./screens/AddProduct";
import Home from "./screens/Home";
import PrivacyPolicyScreen from "./screens/PrivacyPolicyScreen";
import ExcelUpload from "./components/home/ExcelUpload";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  return (
    <Router>
      <Switch>
        <Redirect exact from="/" to="/home" />
        <Route exact path="/home" component={Home} />
        <Route
          exact
          path="/login"
          render={(props) =>
            isLoggedIn ? (
              <Redirect to="/orders" />
            ) : (
              <Login {...props} isLoggedIn={isLoggedIn} />
            )
          }
        />
        <PrivateRouter path="/orders" component={OrderScreen} />
        <PrivateRouter path="/order/:id" component={OrderDetailScreen} />
        <PrivateRouter path="/order" component={OrderDetailScreen} />
        <PrivateRouter path="/products" component={ProductScreen} />
        <PrivateRouter path="/category" component={CategoriesScreen} />
        <PrivateRouter path="/pricelist" component={PriceListScreen} />
        <PrivateRouter path="/banners" component={BannersScreen} />
        <PrivateRouter path="/addbanner" component={AddBanner} />
        <PrivateRouter path="/addproduct" component={AddProduct} />
        <PrivateRouter path="/addcategory" component={AddCategory} />
        <PrivateRouter path="/customers" component={CustomersScreen} />
        <PrivateRouter path="/addcustomer" component={AddCustomer} />
        <PrivateRouter path="/users" component={UsersScreen} />
        <PrivateRouter path="/adduser" component={AddUser} />
        <PrivateRouter path="/units" component={UnitScreen} />
        <PrivateRouter path="/addunit" component={AddUnit} />
        <PrivateRouter path="/orderstatus" component={OrderStatusScreen} />
        <PrivateRouter path="/addorderstatus" component={AddOrderStatus} />
        <PrivateRouter path="/settings" component={SettingsScreen} />
        <PrivateRouter path="/addsetting" component={AddSetting} />
        <Route path="/privacypolicy" component={PrivacyPolicyScreen} />
        <Route path="/test" component={ExcelUpload} />
        <Route path="*" component={NotFound} />
      </Switch>
    </Router>
  );
}

export default App;
