import React from "react";

const ProgressBar = ({
  progress,
  style,
  uploadProgress,
  handleClose,
  message,
}) => {
  return (
    <div className="overlay-progress">
      <div className="progress-bar" style={style}>
        <button className="close-button" onClick={handleClose}>
          &times;
        </button>
        <div className="mb-2">Uploading...</div>
        {uploadProgress > 0 && (
          <div className="progress mb-2" style={{ width: `${progress}%` }}>
            {uploadProgress}%
          </div>
        )}
        {message && <div>{message}</div>}
      </div>
    </div>
  );
};

export default ProgressBar;
