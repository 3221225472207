// src/components/ProductUpload.js
import React, { useState } from "react";
import * as XLSX from "xlsx";
import axios from "axios";
import "./ExcelUpload.css";
import { handleError, handleErrorUpload } from "../../services/functions";
import Snackbar from "../LoadingError/snackbar";
import ProgressBar from "../progressBar";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ButtonLoader from "../LoadingError/buttonLoader";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";

const ExcelUpload = ({ handleClose }) => {
  const [data, setData] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [upload, setUpload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [showProgress, setShowProgress] = useState(false);
  const [uploadProgressMessage, setUploadProgressMessage] = useState("");
  const [snackbarColor, setSnackbarColor] = useState({});
  const history = useHistory();
  const [mssg, setMssg] = useState(false);
  const [progress, setProgress] = useState(0);
  const [showError, setShowError] = useState(false);
  const [errorMssg, setErrorMssg] = useState("");
  const handleFileUpload = (e) => {
    setErrorMssg("");
    setShowError(false);
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const arrayBuffer = event.target.result;
      const workbook = XLSX.read(arrayBuffer, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);
      const formattedData = jsonData.map((item) => {
        const extractId = (value) => {
          const match = value && value?.match(/- (\d+)$/);
          return match ? parseInt(match[1], 10) : null;
        };

        const pricesPerUnit = [
          {
            unit_id: extractId(item["Unit ID 1"]),
            price: item["Price 1"],
            primary: item["Primary 1"],
            min_quantity: item["Min Quantity 1"],
            max_quantity: item["Max Quantity 1"],
            show_price: item["Show Price 1"],
          },
          {
            unit_id: extractId(item["Unit ID 2"]),
            price: item["Price 2"],
            primary: item["Primary 2"],
            min_quantity: item["Min Quantity 2"],
            max_quantity: item["Max Quantity 2"],
            show_price: item["Show Price 2"],
          },
          {
            unit_id: extractId(item["Unit ID 3"]),
            price: item["Price 3"],
            primary: item["Primary 3"],
            min_quantity: item["Min Quantity 3"],
            max_quantity: item["Max Quantity 3"],
            show_price: item["Show Price 3"],
          },
        ].filter((unit) => unit.unit_id && unit.price);

        const specialUnitsPerUnit = [
          {
            unit_id: extractId(item["Special Unit ID 1"]),
            price: item["Special Price 1"],
          },
          {
            unit_id: extractId(item["Special Unit ID 2"]),
            price: item["Special Price 2"],
          },
          {
            unit_id: extractId(item["Special Unit ID 3"]),
            price: item["Special Price 3"],
          },
        ].filter((special) => special.unit_id && special.price);

        const categories = [
          extractId(item["Category 1"]),
          extractId(item["Category 2"]),
          extractId(item["Category 3"]),
        ].filter((category) => category);

        return {
          product_id: item["Product ID"],
          price: item.Price,
          status: item.Status,
          product_description: [
            {
              language: "en",
              name: item["Name (en)"],
              description: item["Description (en)"],
            },
            {
              language: "ar",
              name: item["Name (ar)"],
              description: item["Description (ar)"],
            },
          ].filter((desc) => desc.name && desc.description),
          prices_per_unit: pricesPerUnit.length ? pricesPerUnit : undefined,
          special_prices_per_unit: specialUnitsPerUnit.length
            ? specialUnitsPerUnit
            : undefined,
          categories: categories.length ? categories : undefined,
        };
      });
      setData(formattedData);
      console.log(formattedData);
    };
    reader.readAsArrayBuffer(file);
  };
  const handleCloseProgress = () => {
    setShowProgress(false);
  };

  const handleSendData = async () => {
   
    if (data.length > 0) {
      try {
        setLoading(true);
        const response = await axios({
          method: "post",
          url: "https://tripolifreshmarket-devapi-650ee9764376.herokuapp.com/api/products",
          data: {
            products: data,
          },
          headers: {
            source: "2",
            Authorization:
              "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoxLCJpYXQiOjE3MjE2NDk5MDYsImV4cCI6MTcyNDI0MTkwNn0.VYJmR1QZ6_Ukk-Y33QTAvvkgA35QJnrqT94776k6qys",
          },
          // onUploadProgress: (progressEvent) => {
          //   const { loaded, total } = progressEvent;
          //   const percentage = Math.floor((loaded * 100) / total);
          //   setUploadProgress(percentage);
          //   console.log(uploadProgress);
          // },
          onUploadProgress: (progressEvent) => {
            console.log(progressEvent);
            const progress = (progressEvent.loaded / progressEvent.total) * 50;
            setUploadProgress(progress);
            // console.log(uploadProgress);
            setProgress((prevProgress) =>
              prevProgress >= 100 ? 0 : prevProgress + 10
            );
            // setProgress((oldProgress) => {
            //   console.log("oldProgress", oldProgress);
            //   if (oldProgress === 100) {
            //     return 0;
            //   }
            //   const diff = Math.random() * 10;
            //   return Math.min(oldProgress + diff, 100);
            // });
          },
        });

        if (response.status === 201) {
          // setShowProgress(true);
          setMssg(true);
          setUploadProgressMessage("Uploaded Successfully");
          setSnackbarColor({ backgroundColor: "#4CAF50" });
          setProgress(100);
          setShowError(false);
          setErrorMssg("");
          // setUploadProgress(100);
          setTimeout(() => {
            setShowProgress(false);
            window.location.reload();
          }, 1300);
        } else {
          console.error("Error:", response);
          setUpload(false);
          setUploadProgress(0);
          setProgress(4);
          setShowError(true);
          setTimeout(() => {
            setShowProgress(false);
          }, 1300);
          handleErrorUpload(
            response,
            setErrorMssg,
            // setSnackbarColor,
            setShowError
          );
          // setErrorMssg(setUploadProgressMessage);
        }
      } catch (error) {
        setUploadProgress(0);
        setUpload(false);
        setProgress(2);
        setShowError(true);
        handleErrorUpload(
          error,
          setErrorMssg,
          // setSnackbarColor,
          setShowError
        );
        // setErrorMssg(setUploadProgressMessage);
      } finally {
        setUpload(false);
        setLoading(false);
      }
    } else {
      setUpload(false);
      setSnackbarMessage("No data to upload");
      setSnackbarColor({ backgroundColor: "#FF0000" });
      setShowSnackbar(true);
      setTimeout(() => {
        setShowSnackbar(false);
      }, 3000);
    }
  };

  return (
    <div className="overlay-progress">
      <div className="height-excel">
        <div className="container-excel">
          <button className="close-button" onClick={handleClose}>
            &times;
          </button>
          <h1 className="title-excel">Product Data</h1>

          <input
            type="file"
            accept=".xlsx, .xls"
            onChange={handleFileUpload}
            className="file-input-excel"
          />
          {mssg && (
            <>
              <div style={{ color: "#4CAF50", marginBottom: "5px" }}>
                Uploaded Successfully
              </div>
            </>
          )}
          {showError && (
            <>
              <div style={{ color: "#FF0000", marginBottom: "5px" }}>
                {errorMssg}
              </div>
            </>
          )}
          {uploadProgress > 0 ? (
            <CircularProgress
              variant="determinate"
              value={progress}
              style={{ color: "#4CAF50" }}
            />
          ) : (
            <button onClick={handleSendData} className="send-button-excel">
              <>Upload</>{" "}
            </button>
          )}

          {/* {uploadProgress > 0 && (
            <CircularProgress variant="determinate" value={progress} />
          )} */}
        </div>
        {showSnackbar && (
          <Snackbar message={snackbarMessage} style={snackbarColor} />
        )}
        {showProgress && (
          <ProgressBar
            uploadProgress={uploadProgress}
            handleClose={handleCloseProgress}
            upload={upload}
            style={snackbarColor}
            message={uploadProgressMessage}
          />
        )}
      </div>
    </div>
  );
};

export default ExcelUpload;
